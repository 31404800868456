// const config = {
//   mode : "dev"
// }

const SERVER =  
      document.domain.includes('pigro')
      ?
      "https://api.onespring.co.kr/"
      :
      "https://dev-api.onespring.co.kr/"
      
// console.log("SERVER url",SERVER);      

const ROOT_PATH = "api/v2020/";

export const URLS = {
  user: {
    login: SERVER + ROOT_PATH + "user/login",
    logout: SERVER + ROOT_PATH + "user/logout",
    pageCount: SERVER + ROOT_PATH + "user/pageCount",
    findID: SERVER + ROOT_PATH + "user/findID",
    tempPassword: SERVER + ROOT_PATH + "user/tempPassword",
    changePassword: SERVER + ROOT_PATH + "user/changePassword",
    searchCompanies: SERVER + ROOT_PATH + "user/searchCompanies",
    updateUser: SERVER + ROOT_PATH + "user/updateUser",
    updateUserFree: SERVER + ROOT_PATH + "user/updateUserFree",
    infoUser: SERVER + ROOT_PATH + "user/infoUser",
    getUserType: SERVER + ROOT_PATH + "user/getUserType",
    insertUsersControl: SERVER + ROOT_PATH + "user/insertUsersControl",
  },
  farm: {
    comboCompanies: SERVER + ROOT_PATH + "farm/comboCompanies",
    comboFarms: SERVER + ROOT_PATH + "farm/comboFarms",
    comboBuildings: SERVER + ROOT_PATH + "farm/comboBuildings",
    comboRooms: SERVER + ROOT_PATH + "farm/comboRooms",
  },
  monitor: {
    buildingSummaryView: SERVER + ROOT_PATH + "monitor/buildingSummaryView",
    farmSummaryViewForLite: SERVER + ROOT_PATH + "monitor/farmSummaryViewForLite",
    roomMonitoringSummary: SERVER + ROOT_PATH + "monitor/roomMonitoringSummary",
    farmSummaryView: SERVER + ROOT_PATH + "monitor/farmSummaryView",
    roomDailyView: SERVER + ROOT_PATH + "monitor/roomDailyView",
    room24hoursView: SERVER + ROOT_PATH + "monitor/room24hoursView",
    roomRawDataView: SERVER + ROOT_PATH + "monitor/roomRawDataView",
    monitoringSummary2: SERVER + ROOT_PATH + "monitor/monitoringSummary2",
    monitoringSummary: SERVER + ROOT_PATH + "monitor/monitoringSummary",
    monitoringWeather: SERVER + ROOT_PATH + "monitor/monitoringWeather",
    kepcoDataDailyView: SERVER + ROOT_PATH + "monitor/kepcoDataDailyView",
    listTimelyKepcoData: SERVER + ROOT_PATH + "monitor/listTimelyKepcoData",
  },
  alert:{
    savePersonalChoicedAlerts: SERVER + ROOT_PATH + "alert/savePersonalChoicedAlerts",
    infoPersonalChoicedAlerts: SERVER + ROOT_PATH + "alert/infoPersonalChoicedAlerts",
    listAlertSetupOfFarm: SERVER + ROOT_PATH + "alert/listAlertSetupOfFarm",
    saveAlertSetupOfRoom: SERVER + ROOT_PATH + "alert/saveAlertSetupOfRoom",
  },
  all: {
    baseCodeList: SERVER + ROOT_PATH + "all/baseCodeList",
  },
  test: {
    testParams : SERVER + ROOT_PATH + "test/testParams" ,
    goodLuck : SERVER + ROOT_PATH + "test/goodLuck" ,    
  },
  combo: {
    // getComboList 테스트
    // getComboList: SERVER + ROOT_PATH + "combo/getComboList",
  },
  redis: {
    lastValue: SERVER + ROOT_PATH + "redis/lv",
  },
  mongo: {
    trend: SERVER + ROOT_PATH + "mongo/trend",
  },
};

export default URLS;
