import Vue from "vue";
import { URLS } from "@/api/urls.js";
// import store from "@/store/index.js";

const Apis = {
  // =====================================================================================
  // === API Test ===
  // =====================================================================================
  async getIpClient(callBackFunction) {
    const { data } = await Vue.axios.get('https://ipinfo.io/json?token=7bf79be6d4dfb5');
    return callBackFunction(data);
  },

  async goodLuck(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.test.goodLuck,
      JSON.stringify(params)
    )
    return callBackFunction(data);
  },

  // =====================================================================================
  // === user controller ===
  // =====================================================================================
  async login(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.user.login,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async logout(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.user.logout,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async pageCount(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.user.pageCount,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async findID(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.user.findID,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async tempPassword(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.user.tempPassword,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async changePassword(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.user.changePassword,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async searchCompanies(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.user.searchCompanies,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async updateUser(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.user.updateUser,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async updateUserFree(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.user.updateUserFree,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async infoUser(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.user.infoUser,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async getUserType(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.user.getUserType,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  // =====================================================================================
  // === farm controller ===
  // =====================================================================================
  async comboCompanies(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.comboCompanies,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async comboFarms(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.comboFarms,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async comboBuildings(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.comboBuildings,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async comboRooms( parmas, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.comboRooms,
      JSON.stringify(parmas)
    );
    return callBackFunction(data);
  },
  // =====================================================================================
  // === monitor controller ===
  // =====================================================================================
  async buildingSummaryView(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.monitor.buildingSummaryView,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async roomMonitoringSummary(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.monitor.roomMonitoringSummary,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async farmSummaryView(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.monitor.farmSummaryView,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async farmSummaryViewForLite(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.monitor.farmSummaryViewForLite,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async roomDailyView(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.monitor.roomDailyView,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async room24hoursView(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.monitor.room24hoursView,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async roomRawDataView(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.monitor.roomRawDataView,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async monitoringSummary(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.monitor.monitoringSummary,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async monitoringWeather(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.monitor.monitoringWeather,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async kepcoDataDailyView(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.monitor.kepcoDataDailyView,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async listTimelyKepcoData(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.monitor.listTimelyKepcoData,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  // =====================================================================================
  // === alert ===
  // =====================================================================================
  async savePersonalChoicedAlerts(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.alert.savePersonalChoicedAlerts,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async infoPersonalChoicedAlerts(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.alert.infoPersonalChoicedAlerts,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async listAlertSetupOfFarm(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.alert.listAlertSetupOfFarm,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async saveAlertSetupOfRoom(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.alert.saveAlertSetupOfRoom,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  // =====================================================================================
  // === all controller ===
  // =====================================================================================
  async baseCodeList(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.all.baseCodeList,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  // =====================================================================================
  // === redis controller ===
  // =====================================================================================
  async redisLastValue(params, parseResponse) {
    const { data } = await Vue.axios.get(
      URLS.redis.lastValue + "/" + params.sensor_mac
    );
    return parseResponse(data);
  },
  // =====================================================================================
  // === mongo controller ===
  // =====================================================================================
  async mongoTrand(params, parseResponse) {
    const { data } = await Vue.axios.post(
      URLS.mongo.trend,
      JSON.stringify(params)
    );
    return parseResponse(data);
  },
};

export default Apis;
