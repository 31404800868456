<template>
  <v-app>
    <v-app-bar app color="rgb(58,79,63)" dark height="60vh" style="z-index:99;" v-if="$getters.isLogin">
        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <button style="height: 35px; width: 35; margin-top:5px;" 
              v-show="$route.path !== '/user/login' &&
                      !$route.path.includes('/user/findid') &&
                      !$route.path.includes('/user/findpassword')"
              v-bind="attrs"
              v-on="on"
            >
              <v-img
                alt="로그인"
                location="bottom"
                v-show="!$getters.isLogin" 
                :src="icon_login"
                transition="scale-transition"
                height="30" width="30"
                @click="login()"
              />
              
              <v-img
                v-show="$getters.isLogin" 
                alt="로그아웃"
                location="bottom"
                :src="icon_logout"
                transition="scale-transition"
                height="30" width="30"
                @click="logOut()"
              />
            </button>
          </template>
          <span> {{ loginOutText }} </span>
        </v-tooltip>  -->

        <v-spacer></v-spacer>
        <!-- <button style="margin-top: 30px; margin-right: 1px; height: 36px; width: 36" >
          <v-img
            alt="onespring Logo"
            class="shrink"
            contain
            :src="$getters.isLogin ? logo_white : logo_white"
            transition="scale-transition"
            width="23"
          />
        </button> -->
        <span class="ml-2 page_name mt-1">{{getPageName(this.$route.name) }}</span>

      <v-spacer></v-spacer>

      <v-app-bar-nav-icon @click.stop="drawer_ = !drawer_" @click="refresh_menu">
        <img src="@/assets/navi_menu.svg" alt="메뉴" class="mt-1" />
      </v-app-bar-nav-icon>
            
    </v-app-bar>

    <v-navigation-drawer temporary right height="auto" v-model="drawer_" app absolute v-if="drawer_" style="margin-top:60px;z-index:99;">
      <v-list dense>
        <!-- 2단 메뉴 -->
        <v-list-group
          v-for="item in menu_list"
          :key="item.title"
          v-model="item.active"
          :append-icon="item.econ"
          :prepend-icon="item.icon"
          :hidden="item.hidden"
          no-action
          @click= "item.link && item.link !== $route.path ? goto(item.link) : item.link ? drawer_=false : '' "
        >
          <template v-slot:activator>
            <v-list-item-content>
              <!-- eslint-disable-next-line -->
              <v-list-item-title v-text="item.title" ></v-list-item-title>
            </v-list-item-content>
          </template>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-main> 
      <router-view/>
    </v-main>

    <v-footer app style="height: auto; padding: 0px; z-index:98;" class="footer" v-if="$getters.isLogin">

        <div id="res__container"
          v-if="$route.path !== '/user/login' && $route.path !== '/'" >
          {{ this.$store.state.resMessage }}
        </div>
        <!-- <div v-else id="res__container" >
          스마트 축산 PIGRO LITE system Ver. 23.7
        </div> -->
        
        <div class="button__container">
          <button class="button" @click="back()"> 
            <v-icon color="#3a4f3f">
              mdi-arrow-left-thick
            </v-icon> 
          </button>
          <button class="button position"  @click="$getters.isLogin && $route.path !== '/monitoring/appsummary2' ? $router.push('/monitoring/appsummary2') : ''">
            <v-icon color="#3a4f3f">
              mdi-home
            </v-icon>
          </button>
          <button class="button" @click="forward()">
            <v-icon color="#3a4f3f">
              mdi-arrow-right-bold
            </v-icon>
          </button>
        </div>
    </v-footer>

  </v-app>

</template>

<script>
// var setTime = 0;
// import { mapGetters } from "vuex";
// import Apis from '@/api/apis'
// import VueCookies from "vue-cookies";

export default {
  name: 'App',
  data() {
    return {
        icon_logout: require("@/assets/home/log_out.svg"),
        icon_login: require("@/assets/home/log_in.svg"),

        icon_home_white: require("@/assets/home/icon_home_white.svg"),
        logo_white: require("@/assets/images/logo_white.svg"),
        
        // 드로우 메뉴 리스트
        drawer_: false,
        menu_list: [
                  ],
        menu_initial: [  
                    { icon: 'mdi-monitor-eye', econ: 'mdi-blank', active: false, title: '모니터링 요약', link: "/monitoring/appsummary2",},          // 0
                    { icon: 'mdi-bell', econ: 'mdi-blank', active: false, title: '알람 설정', link: "/Alert/AlertSet", },                 // 8
                    { icon: 'mdi-account-settings', econ: 'mdi-blank', active: false, title: '알람 수신 설정', link: "/Alert/AlertPersonalSet", },                 // 8
                    { icon: 'mdi-account-key', econ: 'mdi-blank', active: false, title: '내정보 수정', link: "/user/myinfo", },                 // 8
                    { icon: 'mdi-logout-variant', econ: 'mdi-blank', active: false, title: '로그아웃', link:'logOut' },                 // 8
                  ],
      };
  },
  created(){
    setInterval( () => {
      this.$store.commit("resMessage","")
    }, 2000);
  },
  mounted(){
  },
  computed: {
    // ...mapGetters(["isLogin", "drawer"]),
    loginOutText() {
      return this.$getters.isLogin ? "로그아웃" : "로그인";
    },
    setHomeBtn() {  
      return this.$getters.isLogin ? this.icon_home_white : this.logo_white;
    },
  },
  methods: {
    back(){
      window.history.back();
      return false ; 
    },
    forward(){
      window.history.go(1);
      return false ; 
    },
    async refresh_menu() {
      this.menu_list = [];
      this.menu_initial.forEach(item => item.hidden = false);
      
      this.menu_list = this.menu_initial; 
    }, 
    login() {
      this.$router.push("/user/login")
    },
    myInfo() {
      if (this.$route.path !== "/user/myinfo") this.$router.push("/user/myinfo")
    },
    goto(path) {

      // console.log("PATH",path);
      if(path == 'logOut'){
        if (this.$getters.isLogin) {
          var logoutConfirm = confirm("로그아웃 하시겠습니까?");
          if (logoutConfirm) {

            this.$dispatch("logout", {
              session_id: this.$getters.sessionId,
              account: this.$getters.userInfo.account,
              user_name: this.$getters.userInfo.name,
            }).then(() => {
              this.$router.push("/user/login");
              }).catch( (err) => {  // 개별 API 오류 처리 예제
              console.log("LogOut 호출 오류2",err);
              this.$router.push("/user/login");
              }) 
          }
        } else {
          if (this.$route.path !== "/login") this.$router.push("/login");
        }
      }else{
        this.drawer_ = false;
        this.$router.push(path);
      }
    },
    toggleDrawer() {
      this.$router.push("/")
      // this.$commit("drawer", !this.drawer);
    },
    getPageName(pageName) {
      // console.log(pageName);
      switch (pageName) {
        case "/":
          return "ONESPRING";
        case "Home":
          return "ONESPRING";
        case "Login":
          return "ONESPRING";
        case "FindAccount":
          return "ID 찾기";
        case "FindPassword":
          return "비밀번호";
        case "Summary":
          return "모니터링 요약";
        // case "AppSummary":
        //   return "모니터링 요약";
          case "AppSummary2":
          return "모니터링 요약";
        case "Barometer":
          return "지표 조회";
        case "AlertSet":
          return "알림 설정";
        case "AlertPersonalSet":
          return "알림 수신 설정";
        case "MyInfo":
          return "내정보 수정";
        case "ChangePW":
          return "비밀번호 변경";
        case "SetInfo":
          return "정보 설정";
        case "WattageDetail":
          return "전력데이터";
        default:
          return pageName;
      }
    },
  },

};
</script>
// 전 화면 적용
<style>   
/* .v-data-table .v-data-table-header tr th {
  font-size: 14px !important;
  font-weight: bold !important;
  color:rgb(244, 244, 244) !important;
  background-color: rgb(77, 111, 86) !important;
} */
.v-main{
  background-color:#f9f9f9 !important;
}
tr.v-row-group__header{
  background-color: #ECECEC !important;
  font-weight:500;
  color:#3A4F3F;
}
tr.v-data-table__selected {
  background: #E8F3F5 !important;
}
.selected {
  background-color: #E8F3F5;
}
</style>

<style lang="scss" scoped>
@font-face {
    font-family: 'GmarketSansMedium';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
.app_bar{
  background-color:#3a4f3f;
  width:100%;
  height:30px;
  z-index:99;
}

$headerHeight: 70px;
$brandColorGreen: #3a4f3f;

@mixin buttonStyle {
  // @include flex_align_center;
  display: flex;
  align-items: center;
  height: 47px;
  border-radius: 26px;
  background-color: white;
  cursor: pointer;
  margin: 0 10px;
  padding: 10px;
}
.page_name {
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  letter-spacing: -0.6px;
}

.page_name > img {
  position: relative;
  height: 24px;
  cursor: pointer;
}

.user_name {
  height: $headerHeight;
  line-height: $headerHeight;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.36px;
  text-align: right;
  color: white;
  cursor: pointer;
  margin-right: 2px;
}


.home_button {
  @include buttonStyle;
  width: auto;
}

.logout_button {
  @include buttonStyle;
  background-color: white !important;
  height: 35 !important;
  width: auto;
}

.footer {
  padding-top: -5px !important;
  color: rgb(255, 255, 189) !important;
  width:100%;
  // background-color: $brandColorGreen !important;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: -1.0px;
}
#res__container{
  width:100%;
  text-align:center;
  background-color: $brandColorGreen !important;
}
.button__container{
  width:100%;
  // border-radius: 30px 30px 0 0;
  background-color:#3a4f3f;
  padding-top: -5px !important;
  height:50px;
  display:flex;
  justify-content: space-between;
}
#res__container{
  z-index:100;
}
.button{
  border: 4px solid #3a4f3f;
  // background-color: #fff7de !important;
  background-image: linear-gradient(120deg, #fff7de 0%, #fffdf8 100%);
  color:$brandColorGreen;
  width:50px;
  height:50px;
  line-height:35px;
  border-radius:50%;
}
.position{
  position:relative;
  top:-15px;
  width:60px;
  height:60px;
}
a {
  text-decoration: none;
}

</style>
