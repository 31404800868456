import Vue from 'vue'
import VueRouter from 'vue-router'

// 모니터링
const AppSummary2 = () => import('@/views/monitoring/AppSummary2.vue');

// const AppSummary = () => import('@/views/monitoring/AppSummary.vue');

const Barometer = () => import('@/views/monitoring/Barometer.vue');
const WattageDetail = () => import('@/views/monitoring/WattageDetail.vue');

// 알림
const AlertSet = () => import('@/views/Alert/AlertSet.vue');
const AlertPersonalSet = () => import('@/views/Alert/AlertPersonalSet.vue');

// 사용자 관련 
// 로그인 화면(회원가입,아이디찾기,비번찾기) 
const Login = () => import('@/views/user/Login.vue');
const FindPassword = () => import('@/views/user/FindPassword.vue');
const FindAccount = () => import("@/views/user/FindAccount.vue");

const MyInfo = () => import('@/views/user/MyInfo.vue'); 

// 테스트 
const ComponentTest = () => import('@/views/ComponentTest.vue');
//const VueStudy = () => import('@/views/VueStudy.vue');

import VueCookies from "vue-cookies";

Vue.use(VueRouter)

const routes = [
  {
    path: '/user/login',
    name: 'Login',
    component: Login,
    beforeEnter: (to, from, next) => {
      let token = VueCookies.get("auth_token");
      // console.log("token",token);
      if (token)
        next('/')
      else
        next()
    },
  },
  {
    path: "/",
    name: "/",
    component: AppSummary2,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },

  // 로그인 관련 -->
  {
    path: '/user/findid',
    name: 'FindAccount',
    component: FindAccount
  },
  {
    path: '/user/findpassword',
    name: 'FindPassword',
    component: FindPassword
  },
  
  // --> 돈방/위치 모니터 링 
  {
    path: '/monitoring/appsummary2',
    name: 'AppSummary2',
    component: AppSummary2,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },  
  // {
  //   path: '/monitoring/appsummary',
  //   name: 'AppSummary',
  //   component: AppSummary,
  //   beforeEnter: redirectTo("isLogin", "/user/login"),
  // },
  {
    path: '/monitoring/barometer',
    name: 'Barometer',
    component: Barometer,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },
  {
    path: '/monitoring/wattagedetail',
    name: 'WattageDetail',
    component: WattageDetail,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },

  // 알림 
  {
    path: '/Alert/AlertSet',
    name: 'AlertSet',
    component: AlertSet,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },
  {
    path: '/Alert/AlertPersonalSet',
    name: 'AlertPersonalSet',
    component: AlertPersonalSet,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },
  // 기본 정보 (사용자,농장/사업장,거래처 등) -->
  {
    path: '/user/myinfo',
    name: 'MyInfo',
    component: MyInfo
  },

  // --> SYSTEM , TEST 등
  {
    path: '/comp_test',
    name: 'ComponentTest',
    component: ComponentTest
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  changeBgColor(to.path);
  if (!hasQueryParams(to) && hasQueryParams(from)) {
    next({ path: to.path, query: from.query });
  } else {
    next();
  }
});

function hasQueryParams(route) {
  return !!Object.keys(route.query).length;
}

function changeBgColor(path) {
  switch (path) {
    case "/":
    case "/findAccount":
    case "/config":
      document.body.style.background = "white";
      // document.body.style.background = "black";
      break;
    case "/login":
      document.body.style.background = "#3a4f3f";
      break;
    default:
      document.body.style.background = "#f8f8f8";
      break;
  }
}

function redirectTo(getterName, redirectPath) {
  return function(to, from, next) {
    // console.log(
    //   "nevigation guard 검증 : getterName : " +
    //     getterName +
    //     ", redirectPath : " +
    //     redirectPath
    // );
    // if (store.getters[getterName]) {
    //   return next();

    let token = VueCookies.get("auth_token");
    // console.log("router -------  auth token")
    // console.log(token)
    if (token) {
      return next()

    } else {
      const redirect = { path: redirectPath };
      if (hasQueryParams(to)) {
        redirect.query = to.query;
      }
      changeBgColor(redirectPath);
      return next(redirect);
    }
  };
}

export default router
