import apis from "@/api/apis.js";

const actions = {
  login({ commit }, params) {
    return apis.login(params, (res) => {
      if (res.result) {
        // console.log('login action')
        // console.log(params)
        commit("sessionId", params.session_id);
        commit("login", res.data);
      }
      return res;
    });
  },
  logout({ commit }, params) {
    // 로그아웃 히스토리 저장
    return apis.logout(params, (res) => {
      if (res.result) {
        commit("sessionId", "");
        commit("logout");
      }
      return res;
    });
  },
  findID(commit, params) {
    return apis.findID(params, (res) => {
      return res;
    });
  },
  tempPassword(commit, params) {
    return apis.tempPassword(params, (res) => {
      return res;
    });
  },
  insertUser(commit, params) {
    return apis.insertUser(params, (res) => {
      return res;
    });
  },
  changePassword({ commit }, params) {
    return apis.changePassword(params, (res) => {
      if (res.result) {
        commit("changePassword", res.data);
      }
      return res;
    });
  },
  // == company ==
  comboCompanies({ commit }, params) {
    return apis.comboCompanies(params, (res) => {
      if (res.result) {
        commit("comboCompanies", res.data);
      }
      return res;
    });
  },
  // == farm ==
  comboBuildings({ commit }, params) {
    return apis.comboBuildings(params, (res) => {
      if (res.result) {
        commit("comboBuildings", res.data);
      }
      return res;
    });
  },
  comboFarms({ commit }, params) {
    return apis.comboFarms(params, (res) => {
      if (res.result) {
        commit("comboFarms", res.data);
      }
      return res;
    });
  },
  comboRooms(commit, params) {
    return apis.comboRooms(params, (res) => {
      if (res.result) {
        // commit("comboRooms", res.data);
      }
      return res;
    });
  },
  // == monitor 관련 API ==
  buildingSummaryView(commit, params) {
    return apis.buildingSummaryView(params, (res) => {
      return res;
    });
  },
  roomDailyView(commit, params) {
    return apis.roomDailyView(params, (res) => {
      return Promise.resolve(res);
    });
  },
  room24hoursView(commit, params) {
    return apis.room24hoursView(params, (res) => {
      return res;
    });
  },
  roomRawDataView(commit, params) {
    return apis.roomRawDataView(params, (res) => {
      return res;
    });
  },
  monitoringSummary(commit, params) {
    return apis.monitoringSummary(params, (res) => {
      return res;
    });
  },
  monitoringWeather(commit, params) {
    return apis.monitoringWeather(params, (res) => {
      return res;
    });
  },

  // == setup ==
  comboDealCompanies(commit, params) {
    return apis.comboDealCompanies(params, (res) => {
      return res;
    });
  },
  // == all ==
  baseCodeList(commit, params) {
    return apis.baseCodeList(params, (res) => {
      return res;
    });
  },
// == redis 관련 API ==
redisLastValue(commit, params) {
  return apis.redisLastValue(params, (res) => {
    console.log("redisLastValue", res);
    return res;
  });
},
};
export default actions;
